import * as React from 'react';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  InputGroup,
  Alert,
} from 'reactstrap';

import styled from 'styled-components';
import { showKeyModal } from '../../operations/showKeyModal';
import { OktaAuth } from '@okta/okta-auth-js';
import * as Model from '../../models';
import { useDispatch } from 'react-redux';
import { InfoIcon } from '@primer/octicons-react';

export interface DispatchProps {
  getKeys: (
    auth: OktaAuth,
    account: Model.Account,
    role: Model.Role,
    duration: number
  ) => void;
}

const ChangeModal = ({ closeModal, account, role, duration, auth, url }) => {
  const [changeNumber, setChangeNumber] = React.useState('');
  const [primaryCINumber, setPrimaryCINumber] = React.useState('');
  const [description, setDescription] = React.useState('');

  const dispatch = useDispatch();

  const handleSubmit = React.useCallback(async () => {
    // if a url is provided to the change modal, then we know we are coming from the "Console" button
    if (url) {
      if (changeNumber) {
        window.open(`${url}?changeNumber=${changeNumber}`);
      }
      if (primaryCINumber) {
        window.open(`${url}?primaryCINumber=${primaryCINumber}`);
      }
    } else {
      // if no url is provided, then we are coming from the "Get Keys" button/dropdown, so we need to show the Key Modal
      dispatch(
        showKeyModal(
          auth,
          account,
          role,
          duration,
          changeNumber,
          primaryCINumber,
          description
        )
      );
    }
  }, [
    url,
    changeNumber,
    primaryCINumber,
    dispatch,
    auth,
    account,
    role,
    duration,
    description,
  ]);

  const isSubmitDisabled: boolean =
    (!changeNumber && !primaryCINumber) ||
    (!!changeNumber && !!primaryCINumber);

  return (
    <div>
      <StyledModalHeader toggle={closeModal}>
        AWS Account Access
      </StyledModalHeader>
      <StyledModalBody>
        <div style={{ marginBottom: '1rem' }}>
          <Label for="requestedDuration" style={{ fontWeight: 'bold' }}>
            Requested Key Duration:
          </Label>
          <div style={{ padding: '0.5rem', borderRadius: '0.25rem' }}>
            {duration} hour(s)
          </div>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Label for="requestedRole" style={{ fontWeight: 'bold' }}>
            Requested Role:
          </Label>
          <div style={{ padding: '0.5rem', borderRadius: '0.25rem' }}>
            {role.name}
          </div>
        </div>
        <StyledAlert color="warning">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon size={24} />
            <div style={{ marginLeft: '1rem' }}>
              For the selected role, please provide either an existing
              ServiceNow Change Ticket # or a Workload Component ID for ALKS to
              create a change ticket on your behalf.
            </div>
          </div>
        </StyledAlert>
        <Label style={{ fontWeight: 'bold' }} for="changeNumber">
          Existing Change Request
        </Label>
        <InputGroup>
          <StyledInput
            id="changeNumber"
            value={changeNumber}
            placeholder="CHGXXXXXXX"
            onChange={(e) => setChangeNumber(e.target.value)}
            disabled={!!primaryCINumber}
          />
        </InputGroup>
        <div style={{ textAlign: 'center', margin: '1rem 0' }}>
          <span>OR</span>
        </div>
        <Label style={{ fontWeight: 'bold' }} for="workloadComponentId">
          Workload Component ID
        </Label>
        <StyledInput
          id="workloadComponentId"
          value={primaryCINumber}
          placeholder="CIXXXXXXX"
          onChange={(e) => setPrimaryCINumber(e.target.value)}
          disabled={!!changeNumber}
        />
        <div style={{ marginBottom: '1rem' }}></div>
        <Label style={{ fontWeight: 'bold' }} for="shortDescription">
          Short Description
        </Label>
        <InputGroup>
          <StyledInput
            type="textarea"
            id="shortDescription"
            placeholder="Optional"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!!changeNumber}
          />
        </InputGroup>
      </StyledModalBody>
      <StyledModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </StyledModalFooter>
    </div>
  );
};

const StyledModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledAlert = styled(Alert)`
  color: ${({ theme }) => (theme.type === 'dark' ? 'secondary' : 'primary')};
`;

const StyledInput = styled(Input)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};

    &&:disabled,
    &&[readonly] {
      background-color: ${({ theme }) => theme.disabledColor};
    }
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${(props) => props.theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  && .close {
    color: ${({ theme }) => theme.textColor};
    text-shadow: 0 1px 0 ${({ theme }) => theme.modal.backgroundColor};
  }
`;

export default ChangeModal;
