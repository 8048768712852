import { Account, Role } from '../models';
import { showModal } from '../store/modal/actions';
import { fetchKeys } from './fetchKeys';
import { ModalType } from '../store/modal/types';
import { Key } from '../models';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';

export const showKeyModal = (
  auth: OktaAuth,
  account: Account,
  role: Role,
  duration: number,
  changeNumber?: string,
  primaryCINumber?: string,
  description?: string
) => async (dispatch: any): Promise<void> => {
  let key: Key;
  try {
    key = await fetchKeys(
      auth,
      account,
      role,
      duration,
      changeNumber,
      primaryCINumber,
      description
    )(dispatch);
  } catch (e) {
    dispatch(
      showModal(ModalType.Alert, {
        title: 'Failed to fetch keys',
        message: `${getErrorMessage(e)}`,
      })
    );
    return;
  }
  dispatch(
    showModal(ModalType.Key, {
      title: account.name + ' ' + role.name + ' Key',
      accessKey: key.accessKey,
      secretKey: key.secretKey,
      sessionToken: key.sessionToken,
      consoleUrl: key.consoleUrl,
    })
  );
};
