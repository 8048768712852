import { ThunkAction } from '../store/shared/types';
import { fetchWithTimeout } from '../util/fetchWithTimeout';
import {
  preferencesFetchHasFailed,
  preferencesFetchHasSucceeded,
  preferencesFetchStarted,
} from '../store/preferences/actions';
import * as config from '../config';
import { toast } from 'react-toastify';
import { PreferenceState } from '../store/preferences/types';
import { initialState as initialPreferenceReduxState } from '../store/preferences/reducer';
import { putUserPreference } from './putUserPreference';
import { getErrorMessage } from '../util/getErrorMessage';
import { OktaAuth } from '@okta/okta-auth-js';
import { themesById } from '../styles/theme';

export const fetchUserPreference = (
  auth: OktaAuth,
  userId: string
): ThunkAction<void> => async (dispatch) => {
  dispatch(preferencesFetchStarted());

  let accessToken: string | undefined;
  try {
    accessToken = auth.getAccessToken();
    if (!accessToken) {
      throw new Error('access token is undefined');
    }
  } catch (e) {
    const reason = `Failed to fetch user preferences. An error occurred when retrieving the stored access token: ${getErrorMessage(
      e
    )}`;
    toast(reason, { type: 'error' });
    dispatch(preferencesFetchHasFailed(reason));
    throw e;
  }

  let response: Response;
  const initialPreferenceState = initialPreferenceReduxState.preferenceState;
  const endpoint = `${config.default.service.url}${config.default.service.userPreferenceService.path}${userId}`;

  try {
    response = await fetchWithTimeout(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Api-Key': config.default.service.apiKey,
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    toast(
      `Failed to fetch user preferences, an unexpected error occurred: ${getErrorMessage(
        e
      )}`,
      { type: 'error' }
    );
    dispatch(preferencesFetchHasFailed(getErrorMessage(e)));
    return;
  }

  if (response.status === 200) {
    const jsonResponse = await response.json();
    if (PreferenceState.is(jsonResponse.data)) {
      dispatch(preferencesFetchHasSucceeded(jsonResponse.data));
      return;
    }
    // if fetched state is malformed try merging it with valid shape first
    const mergedPrefs = { ...initialPreferenceState, ...jsonResponse.data };
    if (PreferenceState.is(mergedPrefs)) {
      // force the fetched theme to conform to one of the existing themes so that new theme fields make their way into users' preferences
      mergedPrefs.theme = themesById[mergedPrefs.theme.id];
      dispatch(preferencesFetchHasSucceeded(mergedPrefs));
      return;
    }
    // if invalid state was returned then dispatch default state
    dispatch(preferencesFetchHasSucceeded(initialPreferenceState));
  } else if (response.status === 404) {
    // this means couldnt find user pref in UPS, will look for local storage prefs and use those or use defaults
    const serializedPreferences = localStorage.getItem('ALKS_PREFS');
    if (serializedPreferences) {
      toast(
        'Could not find user preferences for ' +
          userId +
          ' using local storage preferences',
        { type: 'info' }
      );
      const deserializedPreferences = {
        ...initialPreferenceState,
        ...JSON.parse(serializedPreferences),
      };
      if (PreferenceState.is(deserializedPreferences)) {
        dispatch(preferencesFetchHasSucceeded(deserializedPreferences));
        // let's go ahead and create a record in our preference service store
        dispatch(putUserPreference(auth, userId, deserializedPreferences));
        return;
      } else {
        dispatch(preferencesFetchHasSucceeded(initialPreferenceState));
        // let's go ahead and create a default record in our preference service store
        dispatch(putUserPreference(auth, userId, initialPreferenceState));
      }
    } else {
      toast(
        'Could not find user preferences for ' +
          userId +
          ', using default preferences',
        { type: 'info' }
      );
      dispatch(preferencesFetchHasSucceeded(initialPreferenceState));
      // let's go ahead and create a default record in our preference service store
      dispatch(putUserPreference(auth, userId, initialPreferenceState));
    }
  } else {
    // This means something went wrong in the backend and was unexpected
    toast(
      `Failed to fetch user preferences, received ${response.status} status`,
      { type: 'error' }
    );
    dispatch(
      preferencesFetchHasFailed(
        `Failed to fetch user preferences, received ${response.status} status`
      )
    );
  }
};
