import { Account, Role } from '../models';
import { showModal } from '../store/modal/actions';
import { ModalType } from '../store/modal/types';
import { OktaAuth } from '@okta/okta-auth-js';

export const showChangeModal = (
  account: Account,
  role: Role,
  duration: number,
  auth?: OktaAuth,
  url?: string
) => async (dispatch: any): Promise<void> => {
  dispatch(
    showModal(ModalType.Change, {
      title: 'Change Ticket Validation',
      account: account,
      role: role,
      duration: duration,
      auth: auth,
      url: url,
    })
  );
};
